<template>
  <div v-if="data">
    <div class="col col-offset-12 search">
      <input
        v-model="searchText"
        v-on:keydown="searchTextChange"
        type="text"
        class="lowco-textbox col-4"
        placeholder="Rechercher"
      />
    </div>
    <table>
      <thead>
        <tr>
          <th
            v-for="headerItem in header"
            v-bind:key="headerItem.field"
          >
            {{ headerItem.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="dataItem in filterDataPage"
          v-bind:key="dataItem.id"
        >
          <template
            v-for="headerItem in header"
            v-bind:key="headerItem.field"
          >
            <td v-if="headerItem.icon">
              <button v-on:click="dataItem[headerItem.field]()">
                <i
                  class="fas"
                  v-bind:class="'fa-' + headerItem.icon"
                ></i>
              </button>
            </td>
            <td v-else>
              {{ dataItem[headerItem.field] }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <div class="col col-offset-12 pages">
      <button v-on:click="previousPage">
        <i class="fas fa-caret-left"></i>
      </button>
      <button
        v-for="index in parseInt(filterData.length / itemByPage) + 1"
        v-bind:key="index"
        v-bind:class="{ 'selected': currentPage + 1 === index }"
        v-on:click="goToPage(index - 1)"
      >
        {{ index }}
      </button>
      <button v-on:click="nextPage">
        <i class="fas fa-caret-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  computed: {
    filterData() {
      let filterData = [];
      const searchableFields = this.header
        .filter((i) => i.isSearchable)
        .map((i) => i.field);

      if (this.searchText !== '') {
        this.data.forEach((dataItem) => {
          searchableFields.forEach((searchableField) => {
            if (dataItem[searchableField].includes(this.searchText)) {
              filterData.push(dataItem);
            }
          });
        });
      } else {
        filterData = this.data;
      }

      return filterData;
    },
    filterDataPage() {
      return this.filterData.slice(
        this.currentPage * this.itemByPage,
        (this.currentPage * this.itemByPage) + this.itemByPage,
      );
    },
  },
  data() {
    return {
      searchText: '',
      currentPage: 0,
    };
  },
  methods: {
    searchTextChange() {
      this.currentPage = 0;
    },
    previousPage() {
      if (this.currentPage > 0) { this.currentPage -= 1; }
    },
    goToPage(index) {
      this.currentPage = index;
    },
    nextPage() {
      if (this.currentPage < (this.filterData.length / this.itemByPage) - 1) {
        this.currentPage += 1;
      }
    },
  },
  props: {
    header: Array,
    data: Array,
    itemByPage: Number,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";

$tableBorder: 1px solid $dark-green;
$pageButtonRadius: 10px;

.search {
  text-align: right;
}

.pages {
  margin-top: 2rem;
  text-align: right;

  button {
    cursor: pointer;
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    border: $tableBorder;
    border-right: none;
    padding: 5px 15px;
    min-height: 40px;

    &.selected {
      background-color: $alternate-green;
    }

    &:last-of-type {
      border-right: $tableBorder;
      border-top-right-radius: $pageButtonRadius;
      border-bottom-right-radius: $pageButtonRadius;
    }

    &:first-of-type {
      border-top-left-radius: $pageButtonRadius;
      border-bottom-left-radius: $pageButtonRadius;
    }
  }
}

table {
  border-collapse: collapse;
  margin-top: 1rem;
  width: 100%;

  thead {
    th {
      background-color: $alternate-green;
      border-bottom: $tableBorder;
      padding: 10px;

      &:last-of-type {
        border-right: none;
      }
    }
  }

  tbody {
    tr {
      border-bottom: $tableBorder;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        padding: 5px 0;
        text-align: center;
      }
    }
  }
}
</style>
