<template>
  <div>
    <h3>Factures</h3>
    <template v-if="!hasError">
      <Loader v-if="isLoading" />
      <DataTable
        v-else
        class="data-table"
        :data="invoicesData"
        :header="invoicesHeader"
        :itemByPage="10"
      />
    </template>
    <template v-else>
      <div class="col col-12 center">
        <span class="error"> Erreur lors du chargement des factures. </span>
      </div>
    </template>
  </div>
</template>

<script>
import DataTable from '../components/common/DataTable.vue';
import Loader from '../components/common/Loader.vue';
import lowcoApi from '../api/lowco-api';

export default {
  name: 'Invoices',
  components: {
    DataTable,
    Loader,
  },
  data() {
    return {
      hasError: false,
      isLoading: true,
      invoices: [],
      invoicesHeader: [
        {
          field: 'reference',
          title: 'Numero',
          isSortable: false,
          isSearchable: true,
        },
        {
          field: 'issueAt',
          title: 'Date',
          isSortable: false,
          isSearchable: false,
        },
        {
          field: 'amount',
          title: 'Montant',
          isSortable: false,
          isSearchable: false,
        },
        {
          field: 'vatAmount',
          title: 'TVA',
          isSortable: false,
          isSearchable: false,
        },
        {
          field: 'totalAmount',
          title: 'Total',
          isSortable: false,
          isSearchable: false,
        },
        {
          field: 'file',
          title: 'Telecharger',
          isSortable: false,
          isSearchable: false,
          icon: 'file-pdf',
        },
      ],
    };
  },
  computed: {
    invoicesData() {
      const data = [];

      this.invoices.forEach((invoice) => {
        const issueAt = new Date(invoice.issueAt);
        const ye = new Intl
          .DateTimeFormat('fr', { year: 'numeric' })
          .format(issueAt);

        const mo = new Intl
          .DateTimeFormat('fr', { month: 'short' })
          .format(issueAt);

        const moNum = new Intl
          .DateTimeFormat('fr', { month: '2-digit' })
          .format(issueAt);

        const da = new Intl
          .DateTimeFormat('fr', { day: '2-digit' })
          .format(issueAt);

        data.push({
          id: invoice.code,
          enterprise: invoice.invoice,
          number: invoice.number,
          reference: invoice.reference,
          issueAt: `${da} ${mo} ${ye}`,
          status: invoice.status,
          amount: `${Number.parseFloat(invoice.amount).toFixed(2)} €`,
          vatAmount: `${Number.parseFloat(invoice.vatAmount).toFixed(2)} €`,
          totalAmount: `${Number.parseFloat(invoice.totalAmount).toFixed(2)} €`,
          file: async () => {
            const invoicePdf = (await lowcoApi.getInvoicePdf(invoice.code))
              .data;

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(invoicePdf);
              return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const pdfFile = window.URL.createObjectURL(invoicePdf);
            const link = document.createElement('a');
            link.href = pdfFile;
            link.download = `invoice-${da}-${moNum}-${ye}.pdf`;
            link.click();
            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
              link.remove();
            }, 100);
          },
        });
      });

      return data.sort((a, b) => {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
      });
    },
  },
  async created() {
    const enterpriseIds = '';

    this.isLoading = true;
    try {
      if (enterpriseIds.length === 1) {
        this.invoices = (await lowcoApi.getInvoices(enterpriseIds[0])).data;
        this.isLoading = false;
      } else {
        // TODO: Allow user to select the enterprise.
        this.invoices = (await lowcoApi.getInvoices()).data;
        this.isLoading = false;
      }
    } catch {
      this.isLoading = false;
      this.hasError = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/common/variables.scss";

h3 {
  margin-bottom: 1rem;
}

.data-table {
  max-width: 1000px;
}

.error {
  color: $error;
}
</style>
